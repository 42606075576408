<template>
  <div
    id="register"
    :style="{
      'background-position': 'center center',
      'background-image': 'url(' + backgroundImg(advert.image) + ')',
      height: '654px',

    }"
  >
    <div class="wrap">
      <div class="login_item">
        <div class="title">会员注册</div>
        <div class="inputs">
          <img src="@/assets/img/login-01.png" alt="" />
          <input
            type="text"
            v-model="phone"
            placeholder="手机"
            autocomplete="new-password"
          />
        </div>
        <div class="inputs">
          <i class="icon-font el-icon-picture"></i>
          <input
            type="text"
            @keyup.enter="submit"
            v-model="verifyCode"
            placeholder="图片验证码"
            maxlength="6"
            autocomplete="new-password"
          />
          <div
            class="imgCodeMode"
            @click="getVerifyCode"
            v-loading="verifysignLoding"
          >
            <img :src="codeUrl" alt="" />
          </div>
        </div>
        <div class="inputs">
          <i class="icon-font el-icon-message"></i>
          <input
            type="text"
            placeholder="手机验证码"
            maxlength="6"
            v-model="code"
            autocomplete="new-password"
          />
          <div class="pointer po1" @click="sendCode" v-if="verifysignShow">
            发送验证码
          </div>
          <div class="pointer po2" v-else>{{ getVerification }}</div>
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-04.png" alt="" />
          <input
            type="text"
            v-model="qq"
            placeholder="QQ"
            autocomplete="new-password"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/wechat.png" alt="" />
          <input
            type="text"
            v-model="wechat"
            placeholder="微信"
            autocomplete="new-password"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-02.png" alt="" />
          <input
            type="text"
            v-model="invitorCode"
            placeholder="邀请码,可不填"
            autocomplete="new-password"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-03.png" alt="" />
          <input
            type="password"
            v-model="password"
            placeholder="密码"
            autocomplete="new-password"
          />
        </div>
        <div class="inputs">
          <img src="@/assets/img/login-03.png" alt="" />
          <input
            type="password"
            v-model="checkPassword"
            placeholder="确认密码"
            autocomplete="new-password"
          />
        </div>
        <div class="rem">
          <el-checkbox fill="#409eff" v-model="checked" />
          已仔细阅读并同意
          <span class="protocol" @click="dialogVisible = true"
            >《用户使用协议》</span
          >
        </div>
        <div class="btn" @click="submit">注 册</div>
      </div>
    </div>
    <el-dialog
      title="《用户使用协议》"
      :visible.sync="dialogVisible"
      width="1200px"
    >
      <p>
        <br />1、商家服务协议<br />欢迎您注册成为{{
          $store.state.tenantInfo.company
        }}商家会员，在注册前请您仔细阅读以下服务条款。<br /><br />1、本协议内容包括以下条款及本网站已经发布的或将来可能发布的各类规则。所有规则为本协议不可分割的一部分，与协议正文具有同等法律效力。本协议是您与本网站共同签订的，适用于您在本网站的全部活动。在您注册成为用户时，您已经阅读、理解并接受本协议的全部条款及各类规则，并承诺遵守中国的各类法律规定，如有违反而导致任何法律后果的发生，您将以自己的名义独立承担所有相应的法律责任。<br /><br />2、本网站有权根据需要不时地修改本协议或根据本协议制定、修改各类具体规则并在本网站相关系统板块发布，无需另行单独通知您。您应不时地注意本协议及具体规则的变更，若您在本协议及具体规则内容公告变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的协议和具体规则内容，也将遵循修改后的协议和具体规则使用本网站的服务；同时就您在协议和具体规则修订前通过本网站进行的交易及其效力，视为您已同意并已按照本协议及有关规则进行了相应的授权和追认。若您不同意修改后的协议内容，您应停止使用本网站的服务。<br /><br />3、您通过自行或授权有关方根据本协议及本网站有关规则、说明操作确认本协议后，本协议即在您和本网站之间产生法律效力。本协议不涉及您与本网站的其他用户之间因网上交易而产生的法律关系或法律纠纷，但您在此同意将全面接受和履行与本网站其他
        用户在本网站签订的任何电子法律文本，并承诺按该等法律文本享有和/或放弃相应的权利、承担和/或豁免相应的义务。<br /><br />协议细则：<br />一、注册须知<br />1、商家注册帐号时应认真填写用户名，一个商家帐号对应一个用户名，用户名注册成功后无法修改。一旦加入{{
          $store.state.tenantInfo.company
        }}VIP会员，不得退款。商家填写的商家名称及商家店铺名称应遵守《中华人民共和国相关法律法规》，不允许包含违法、涉嫌侵犯他人权利或干扰{{
          $store.state.tenantInfo.company
        }}运营秩序等相关信息。<br /><br />2、注册商家帐号应使用店铺相关负责人的QQ、电话来注册。商家有义务妥善保管及正确使用账号与密码，因未尽上述义务导致帐号信息泄露、密码遗失、账号被盗等情况引起的经济纠纷或损失由商家自行承担。<br /><br />3、用户不应将其帐号、密码转让、出售或出借予他人使用，如果{{
          $store.state.tenantInfo.company
        }}发现使用者并非帐号注册者本人，{{
          $store.state.tenantInfo.company
        }}有权停止继续服务。如用户发现其帐号遭他人非法使用，应立即通知{{
          $store.state.tenantInfo.company
        }}。因黑客行为或用户违反本协议规定导致帐号、密码遭他人非法使用的，由用户本人承担因此导致的损失和一切法律责任，不承担任何责任。<br /><br />4、{{
          $store.state.tenantInfo.company
        }}有权对平台各项服务条款及使用规则等进行调整。用户在使用相关服务时,应关注平台的最新动向并遵守平台的服务条款及使用规则。<br /><br /><br />二、服务内容<br /><br />1、{{
          $store.state.tenantInfo.company
        }}服务的具体内容由{{
          $store.state.tenantInfo.company
        }}根据实际情况提供；除非本服务协议另有其它明示规定，{{
          $store.state.tenantInfo.company
        }}所推出的新产品、新功能、新服务，均受到本服务协议之规范。
        <br /><br />2、鉴于网络服务的特殊性，用户须同意{{
          $store.state.tenantInfo.company
        }}有权不经事先通知，随时变更、中断或终止部分或全部的服务（包括收费服务）。{{
          $store.state.tenantInfo.company
        }}不担保网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作担保。<br /><br />3、{{
          $store.state.tenantInfo.company
        }}需定期或不定期地对提供用户服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，{{
          $store.state.tenantInfo.company
        }}无需为此承担任何责任。{{
          $store.state.tenantInfo.company
        }}保留不经事先通知为维修保养、升级或其它目的暂停本服务任何部分的权利。<br /><br />三、使用规则<br />1、用户在使用{{
          $store.state.tenantInfo.company
        }}服务时，必须遵守《中华人民共和国相关法律法规》的规定，用户应同意将不会利用{{
          $store.state.tenantInfo.company
        }}平台进行任何违法或不正当的活动，包括但不限于下列行为∶<br /><br />（1）上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：<br /><br />①
        反对宪法所确定的基本原则的；<br /><br />②
        危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br /><br />③
        损害国家荣誉和利益的；<br /><br />④
        煽动民族仇恨、民族歧视、破坏民族团结的；<br /><br />⑤
        破坏国家宗教政策，宣扬邪教和封建迷信的；<br /><br />⑥
        散布谣言，扰乱社会秩序，破坏社会稳定的；<br /><br />⑦
        散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br /><br />⑧
        侮辱或者诽谤他人，侵害他人合法权利的；<br /><br />⑨
        含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；<br /><br />（2）
        含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；<br /><br />①不得为任何非法目的而使用网络服务系统；<br /><br />（3）不利用{{
          $store.state.tenantInfo.company
        }}服务从事以下活动：<br /><br />①未经允许，进入计算机信息网络或者使用计算机信息网络资源的；<br /><br />②未经允许，对计算机信息网络功能进行删除、修改或者增加的；<br /><br />③未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；<br /><br />④故意制作、传播计算机病毒等破坏性程序的；<br /><br />⑤其他危害计算机信息网络安全的行为。<br /><br />（4）用户违反本协议或相关的服务条款的规定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，{{
          $store.state.tenantInfo.company
        }}有权视用户的行为性质，采取包括但不限于删除用户发布信息内容、暂停使用许可、终止服务、限制使用、回收{{
          $store.state.tenantInfo.company
        }}帐号、追究法律责任等措施。对恶意注册{{
          $store.state.tenantInfo.company
        }}帐号或利用{{
          $store.state.tenantInfo.company
        }}帐号进行违法活动、捣乱、骚扰、欺骗、其他用户以及其他违反本协议的行为，{{
          $store.state.tenantInfo.company
        }}有权回收其帐号。同时，{{
          $store.state.tenantInfo.company
        }}会视司法部门的要求，协助调查。<br /><br />①用户须对自己在使用{{
          $store.state.tenantInfo.company
        }}服务过程中的行为承担法律责任。用户承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在{{
          $store.state.tenantInfo.company
        }}首先承担了因用户行为导致的行政处罚或侵权损害赔偿责任后，用户应给予{{
          $store.state.tenantInfo.company
        }}等额的赔偿。<br /><br />②用户不得对本服务任何部分或本服务之使用或获得，进行复制、拷贝、出售、转售或用于任何其它商业目的。<br /><br />③商家会员在活动过程中应遵守规则。{{
          $store.state.tenantInfo.company
        }}不认可商家私下通过旺旺、QQ等方式私下与用户达成的所有约定，如出现用户纠纷，{{
          $store.state.tenantInfo.company
        }}以《{{
          $store.state.tenantInfo.company
        }}商家规则》为依据处理，商家承担所有因私下承诺造成的所有后果。<br /><br />④{{
          $store.state.tenantInfo.company
        }}仅作为用户提供商品购买渠道及信息交流平台，所有产品均由商家用户直接提供，商家有权利和义务对发布任务的产品负责，杜绝一切非正规渠道来源的产品。因产品本身及用户的个体差异而有所不同，如用户会员在使用过程中出现不适，商家有义务对商品出现的问题承担全部责任。<br /><br />⑤{{
          $store.state.tenantInfo.company
        }}有权对违反{{
          $store.state.tenantInfo.company
        }}《商家规则》的商家进行对应的处罚。<br /><br />四、通告<br />所有发给用户的通告都可通过重要页面的公告或电子邮件或常规的信件传送。服务条款的修改、服务变更、或其它重要事件的通告都会以此形式进行。<br /><br />五、信息内容的所有权<br />{{
          $store.state.tenantInfo.company
        }}所有知识产权、网站中的信息内容或作为服务内容的信息、数据库、所有网站设计、文字和图表、软件、照片、录像、音乐、声音及其前述组合，以及所有软件编译、相关源代码和软件
        (包括小应用程序和脚本) 均为{{
          $store.state.tenantInfo.company
        }}的财产。不得意图非法获得该知识产权。未经{{
          $store.state.tenantInfo.company
        }}书面允许，不得为商业目的复制、更改、拷贝、发送或使用{{
          $store.state.tenantInfo.company
        }}的任何材料或内容，如因用户的行为而给{{
          $store.state.tenantInfo.company
        }}造成损害，{{
          $store.state.tenantInfo.company
        }}有权视情节严重，将通过法律途径追索经济和法律责任。<br /><br />六、其他<br />1、本协议的订立、执行和解释及争议的解决均应适用《中华人民共和国法律》<br /><br />2、如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决。<br /><br />3、如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。<br /><br />请您在发现任何违反本服务协议以及其他任何单项服务的服务条款、各类公告之情形时，通知{{
          $store.state.tenantInfo.company
        }}。我们将竭诚为您服务！<br /><br />{{
          $store.state.tenantInfo.company
        }}商家规则<br />第一章 概述<br />第一条
        为了保障商家和用户的合法权益，制定此规则。 第二条
        商家有权利及义务遵循《{{
          $store.state.tenantInfo.company
        }}商家规则》的有关条款。 <br />第三条
        针对商家的违规行为的认定与处理，应基于{{
          $store.state.tenantInfo.company
        }}认定的事实并严格依规执行。 <br />第四条
        商家会员在活动过程中应遵守规则。{{
          $store.state.tenantInfo.company
        }}不认可商家私下通过旺旺、QQ等方式私下与{{
          $store.state.tenantInfo.company
        }}用户达成的所有约定，如出现用户纠纷，{{
          $store.state.tenantInfo.company
        }}以《{{ $store.state.tenantInfo.company }}商家规则》和《{{
          $store.state.tenantInfo.company
        }}商家处罚规则》为依据处理，商家承担所有因私下承诺造成的所有后果。
        <br />第五条
        {{
          $store.state.tenantInfo.company
        }}仅作为用户提供商品购买渠道及信息交流平台，所有活动产品均由合作商家直接提供，商家有权利和义务对发布任务的产品负责，杜绝一切非正规渠道来源的活动产品。因产品本身及用户的个体差异而有所不同导致{{
          $store.state.tenantInfo.company
        }}用户在使用过程中出现不适，商家有责任及义务配合{{
          $store.state.tenantInfo.company
        }}用户处理使用商家商品后出现的不适问题。 <br />第六条 商家同意注册{{
          $store.state.tenantInfo.company
        }}，视为认可并且同意《{{
          $store.state.tenantInfo.company
        }}商家规则》、《{{
          $store.state.tenantInfo.company
        }}商家处罚规则》、《{{
          $store.state.tenantInfo.company
        }}商品发布规范》下的所有条款，同时{{
          $store.state.tenantInfo.company
        }}有权变更本规范并在网站上予以公告，自公告之日起产生的所有问题，按照更新后的条例处理。商家可在网站页面随时关注规则变更条款，买家和（或）卖家不同意相关变更的，可通过法律途径解决争议。
        <br />第七条
        {{
          $store.state.tenantInfo.company
        }}通过站内信、QQ、电话、电子邮件、短信或电话等方式向买卖双方发送的相关的提示或通知，构成{{
          $store.state.tenantInfo.company
        }}各项事宜的处理依据及结论的有效组成部分 <br />第八条《{{
          $store.state.tenantInfo.company
        }}商家规则》和《{{
          $store.state.tenantInfo.company
        }}商家处罚规则》的最终解释权归{{
          $store.state.tenantInfo.company
        }}所有。<br /><br />第二章 注册<br />第一条
        您在注册帐号或使用本服务的过程中，可能需要填写一些必要的信息。若国家法律法规有特殊规定的，您需要填写真实的身份信息。若您填写的信息不完整，则无法使用本服务或在使用过程中受到限制。
        <br />第二条
        一般情况下，您可随时浏览、修改自己提交的信息，但出于安全性和身份识别（如账户申诉或者账户修改等服务）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。
        <br />第三条
        注册商家帐号应使用店铺相关负责人的QQ、电话、邮箱来注册。商家有义务妥善保管及正确使用账号与密码，因未尽上述义务导致帐号信息泄露、密码遗失、账号被盗等情况引起的经济纠纷或损失由商家自行承担。<br />第四条
        商家注册帐号时应认真填写用户名，一个商家帐号对应一个用户名，用户名注册成功后无法修改。商家填写的商家名称及商家店铺名称应遵守国家法律法规，不允许包含违法、涉嫌侵犯他人权利或干扰{{
          $store.state.tenantInfo.company
        }}运营秩序等相关信息。<br />第五条
        商家应自行保管好商家帐号的登录密码及密码。忘记密码可在网站上根据提示进行找回，同时{{
          $store.state.tenantInfo.company
        }}账户一旦注册成功，目前暂不支持注销。<br /><br /><br />第三章
        管辖与法律适用<br />第一条
        本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。<br />第二条
        本协议签订地为中华人民共和国江西省赣州市石城县。 <br />第三条 若您和{{
          $store.state.tenantInfo.company
        }}之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地（即中国江西省赣州市石城县）有管辖权的人民法院管辖。
        <br />第四条
        本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。
        <br />第五条
        本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。<br /><br /><br /><br /><br /><br />
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import rsa from "@/utils/rsa";

export default {
  name: "",
  data() {
    return {
      phone: "",
      qq: "",
      wechat: "",
      checked: false,
      dialogVisible: false,
      checkPassword: "",
      password: "",
      loading: false,
      advert: {},
      verifysignLoding: false,
      codeUrl: "",
      verifyCode: "",
      code: "",
      verifysignShow: true,
      invitorCode: localStorage.getItem("inviteCode"),
      getVerification: "60s后重新获取",
    };
  },
  created() {
    this.getVerifyCode();
    this.getAdvertList();
    this.IsPC();
  },
  mounted() {},
  watch: {},
  methods: {
    IsPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = new Array(
        "Android",
        "iPhone",
        "SymbianOS",
        "Windows Phone",
        "iPad",
        "iPod"
      );
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      if (!flag) {
        this.$router.push("/m_register");
      }
    },
    backgroundImg(value) {
      var host = document.location.host;
      var result = "";
      if (value && value.indexOf("files") != -1) {
        if (host.search("192") != -1) {
          result = "http://192.168.0.172:8080/" + value;
        } else {
          result = document.location.protocol + "//" + host + "/" + value;
        }
      } else {
        result = value;
      }
      return result;
    },
    submit() {
      if (this.loading) return;
      this.loading = true;
      const loading = this.$loading();
      if (!/^[1][0-9][0-9]{9}$/.test(this.phone)) {
        this.$message.warning("手机号格式错误");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.code == "") {
        this.$message.warning("请输入手机验证码");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.qq == "") {
        this.$message.warning("请输入QQ号");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.password == "") {
        this.$message.warning("请输入密码");
        this.loading = false;
        loading.close();
        return;
      }
      if (this.password != this.checkPassword) {
        this.$message.warning("两次输入密码不一致");
        this.loading = false;
        loading.close();
        return;
      }
      if (!this.checked) {
        this.$message.warning("请细读并同意 《用户使用协议》");
        this.loading = false;
        loading.close();
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/user/register",
        params: {
          code: this.code,
          inviteCode: this.inviteCode,
          password: this.password,
          mobile: this.phone,
          qq: this.qq,
          sign: this.codeSign,
          wechat: this.wechat,
          verifySign: this.verifysign,
          verifyCode: this.verifyCode,
          invitorCode: this.invitorCode,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {

          this.$message.success("注册成功");
          this.$router.push("/login");
        },
        finally: () => {
          this.loading = false;
          loading.close();
          this.getVerifyCode();
        },
      });
    },
    sendCode() {
      let reg = 11 && /^[1][0-9][0-9]{9}$/;
      if (!reg.test(this.phone)) {
        this.$message.warning("请输入正确的手机号码");
        return;
      }
      if (this.verifyCode == "") {
        this.$message.warning("请输入图片验证码");
        return;
      }
      this.$request.post({
        url: "/ReportApi/business/basic/sendSms",
        params: {
          mobile: this.phone,
          verifyCode: this.verifyCode,
          verifySign: this.verifysign,
        },
        success: (res) => {
          this.codeSign = res;
          this.$message.success("发送成功");
          let time = 60;
          this.verifysignShow = false;
          let Time = setInterval(() => {
            if (time == 1) {
              this.verifysignShow = true;
              clearInterval(Time);
            } else {
              time--;
              this.getVerification = `${time}s后重新获取`;
            }
          }, 1000);
        },
        error: (res) => {
          this.getVerifyCode();
        },
        finally: () => {

        },
      });
    },
    getVerifyCode() {
      this.verifysignLoding = true;
      this.$request.post({
        url: "/ReportApi/basic/system/getVerifyCode",
        success: (res) => {
          let codeImg = res.value;
          this.codeUrl = codeImg;
          this.verifysign = res.key;
          this.verifysignLoding = false;
        },
      });
    },
    getAdvertList() {
      this.$request.post({
        url: "/ReportApi/business/advert/getAdvertInfo",
        params: {
          type: 3,
          tenantId: localStorage.getItem("tenantInfoid"),
        },
        success: (res) => {
          if (res.length > 0) {
            this.advert = res[0];
          }
        },
        finally: () => {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#register {
  background: #de2216;
  .wrap {
    position: relative;
    img {
      display: block;
    }
  }
  .login_item {
    position: absolute;
    width: 350px;
    right: 0;
    top: 20px;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 10px;
    .title {
      text-align: center;
      font-size: 20px;
      color: #8c8c8c;
      line-height: 40px;
      margin-bottom: 30px;
    }
    .inputs {
      position: relative;
      margin-bottom: 15px;
      border: 1px solid #e1e1e1;
      height: 36px;
      .pointer {
        position: absolute;
        top: 0;
        right: 0;
        background: #ea242f;
        color: #fff;
        height: 36px;
        line-height: 36px;
        font-size: 14px;
        padding: 0 20px;
        cursor: pointer;
        border-radius: 3px;
      }












      .imgCodeMode {
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        img {
          margin: 0;
          height: 100%;
          width: auto;
          display: block;
        }
        &:hover {
          cursor: pointer;
        }
      }
      input {
        border: none;
        color: #666;
        outline: none;
        height: 36px;
        font-size: 16px;
        width: 240px;
        vertical-align: bottom;
      }
      .icon-font {
        font-size: 18px;
        margin: 8px;
        color: #cacaca;
      }
      img {
        width: 25px;
        display: inline-block;
        margin: 0 5px 2px;
      }
    }
    .rem {
      font-size: 14px;
      margin-top: -7px;
      margin-bottom: 13px;
      .protocol {
        color: #ea242f;
        cursor: pointer;
      }
    }
    .btn {
      width: 100%;
      line-height: 38px;
      background: #ea242f;
      color: #fff;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;
    }
    .other {
      margin-top: 10px;
      font-size: 12px;
      color: #666;
      span {
        &:last-child {
          padding-left: 5px;
        }
        &:hover {
          cursor: pointer;
          color: #ea242f;
        }
      }
    }
  }
}
</style>
